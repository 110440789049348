import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PartnersCard = (props) => {
  const { t } = useTranslation(["translation", "partners"]);
  const navigate = useNavigate();

  return (
    <motion.div
      className="relative cursor-pointer"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={null}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 694 800"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <pattern
            id={`img_${props.item.logo}`}
            patternUnits="userSpaceOnUse"
            width="100%"
            height="200%"
          >
            <image
              href={require(`../../assets/images/partners/${props.item.logo}`)}
              x="-25%"
              y="-25%"
              width="150%"
              height="150%"
            />
          </pattern>
        </defs>
        <path
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 5, ease: "easeInOut" },
          }}
          d="M1.58984 200.577L347 1.1547L692.41 200.577V599.423L347 798.845L1.58984 599.423V200.577Z"
          className="shadow"
          fill={`url(#img_${props.item.logo})`}
          stroke="#FBB500"
          stroke-width="5"
        />
      </svg>
      <div
        className="
                    text-xl
                    font-opensans
                    font-bold
                    text-white
                    bg-green-600
                    rounded-xl
                    shadow-lg
                uppercase text-center"
      >
        {t(`partners:${props.item.name}.title`)}
      </div>
    </motion.div>
  );
};

export default PartnersCard;

import React from "react";
import { products } from "../xmls/products";
import { useTranslation } from "react-i18next";

import NewProductCard from "../general/NewProductCard";

const Products = () => {
  window.scrollTo(0, 0);

  const { t, i18n } = useTranslation(["translation", "products"]);
  return (
    <div className="bg-office min-h-screen bg-cover pt-24 pb-10 bg-bottom -mt-20">
      <div className="container mx-auto px-6 bg-white py-10 bg-opacity-70 rounded-2xl shadow-2xl">
        <div className="text-center text-4xl font-lobster font-bold uppercase pb-3 border-b-4 border-yellow-600">
          {t("products")}
        </div>
        <section id="products">
          <div className="grid grid-cols-1 gap-6 pt-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {products.map((item, index) => {
              return <NewProductCard key={index} item={item} />;
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Products;

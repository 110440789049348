import React from "react";
import { Link } from "react-router-dom";
import { allLinks } from "../xmls/Links";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation([
    "translation",
    "products",
    "images",
    "partners",
  ]);
  return (
    <section className="bg-gray-100 p-5 bottom-0">
      <div className="flex flex-wrap md:justify-evenly sm:justify-center sm:gap-4">
        {Object.keys(allLinks).map((key) => {
          return (
            <div key={key} className="border-r pr-3 w-max mt-2">
              <div className="uppercase font-roboto font-semibold">
                {t(key)}
              </div>
              <div>
                {allLinks[key].map((item, index) => {
                  return (
                    <div key={index}>
                      {item.url.indexOf("http") > -1 ? (
                        <a
                          href={item.url}
                          className="text-xs hover:underline hover:text-gray-800 text-gray-600"
                        >
                          {t(`partners:${item.name}.title`)}
                        </a>
                      ) : (
                        <Link
                          className="text-xs hover:underline hover:text-gray-800 text-gray-600"
                          to={item.url}
                        >
                          {t(item.name)}
                        </Link>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="border-r pr-3 w-max mt-2">
          <div className="uppercase font-roboto font-semibold">
            {t("contactstitle")}
          </div>
          <div className="font-sans overflow-clip text-sm">
            <div>{t("contacts.address")}</div>
            <div className="flex font-bold">
              {t("contacts.phonetitle")}
              <div className="px-2  font-thin">
                <div>{t("contacts.telephone")}</div>
              </div>
            </div>
            <div className="flex font-bold">
              {t("contacts.emailtitle")}
              <div className="px-2 font-thin">
                <div>{t("contacts.email")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t row-span-1 mt-3 m-auto items-center w-full text-center p-2 font-ptsansnarrow text-sm text-gray-400">
        <div>
          &copy; {new Date().getFullYear()} {t("allrightsreserved")}
        </div>{" "}
        {t("companyName")}
      </div>
    </section>
  );
};

export default Footer;

import React, { useState } from 'react'

const CustomTextArea = (props) => {
    const [noc, setNoc] = useState(0);

    const handleChange = (event) => {
        setNoc(event.target.value.legth)
    }

    return (
        <div>
            <textarea onChange={handleChange} cols={props.cols} rows={props.rows} placeholder={props.placeholder}
                className={`focus: outline-none w-full border 
                 rounded-2xl text-lg p-2 
                 placeholder-gray-500 
                 ${props.errors != null ? "focus:border-red-500 border-red-500" :
                        "focus:border-blue-400 border-gray-400"}`}
                maxLength={props.maxChars}
                {...props.register(props.name, props.validation)}>
            </textarea>
            <div className="text-gray-500 text-xs flex justify-between my-1">
                <div className="text-red-500 text-sm font-sans">{props.errors && props.errors}</div>
                <div>{noc}/{props.maxChars}</div>

            </div>

        </div>
    )
}

export default CustomTextArea

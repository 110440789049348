import React from "react";
import Hexagon from "../general/Hexagon";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation(["translation", "products"]);

  const items = [
    "translation:aboutus.aboutus1",
    "translation:aboutus.aboutus2",
  ];

  return (
    <div className="bg-header-image min-h-screen bg-cover bg-center flex flex-col lg:flex-row justify-evenly -mt-20  pt-7">
      <div className="flex-1 m-auto text-center pt-44 lg:pt-0">
        <div className="text-white font-semibold font-ptsansnarrow mb-10 space-y-10 px-2">
          <p className="md:text-6xl text-4xl uppercase">
            "{t("translation:slogan")}"
          </p>
          <p className="md:text-4xl text-3xl">{t("translation:saying")}</p>
        </div>
      </div>
      <div className="flex-1 m-auto p-10 text-center">
        <Hexagon
          className="mx-auto"
          title={"translation:companyName"}
          items={items}
        />
      </div>
    </div>
  );
};

export default Home;

import React, { useCallback, useState } from "react";
import { Transition } from "@headlessui/react";
import { NavbarMenu } from "./NavbarMenu";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  const handleCallback = useCallback((childNavbarStatus) => {
    setNavbar(childNavbarStatus);
  });

  return (
    <div className="sticky top-0 z-10 bg-white bg-opacity-30 hover:bg-opacity-90">
      <NavbarMenu navbarStatus={handleCallback} />
    </div>
  );
};

export default Navbar;

export const products = [
  {
    name: "honey",
    mainImage: "honey.JPG",
    images: [
      "honey.JPG",
      "honey2.jpg",
      "honey3.jpg",
      "honey4.jpg",
      "honeyexport.jpg",
      "honeyframe6.jpg",
      "colony5.jpg",
    ],
  },
  {
    name: "queens",
    mainImage: "queen.jpg",
    images: [
      "queens.JPG",
      "queens2.jpg",
      "queens3.jpg",
      "queencell.jpg",
      "queen.jpg",
      "queenwithmark.jpg",
      "queenwithmark2.JPG",
      "micronucframe2.jpg",
    ],
  },
  {
    name: "workshop",
    mainImage: "beehives.JPG",
    images: [
      "beehives.JPG",
      "beehives2.jpg",
      "beehives3.jpg",
      "beehives4.jpg",
      "beehives5.jpg",
      "hives2.jpg",
      "productionhives.jpg",
      "worker.jpg",
    ],
  },
  {
    name: "candyproduction",
    mainImage: "candymachines.JPG",
    images: ["candymachines.JPG"],
  },
  {
    name: "courses",
    mainImage: "courses.JPG",
    images: [
      "courses.JPG",
      "courses2.JPG",
      "courses23.jpg",
      "gaydar.jpg",
      "gaydar2.jpg",
      "ukr.jpg",
      "ukr1.jpg",
      "classroom.jpg",
      "classroom2.jpg",
    ],
  },
  {
    name: "mellifirousplants",
    mainImage: "facelia2.jpg",
    images: [
      "raps.jpg",
      "facelia.JPG",
      "facelia3.jpg",
      "seeds.jpg",
      "faceliabee2.JPG",
      "faceliabee.JPG",
    ],
  },
  {
    name: "beepackages",
    mainImage: "packages.JPG",
    images: ["packages.JPG"],
  },
  {
    name: "propolis",
    mainImage: "honeyframe7.jpg",
    images: ["honeyframe7.jpg", "propolis.jpg", "propolis2.jpg"],
  },

  {
    name: "researches",
    mainImage: "pollination.jpg",
    images: [
      "pollination.jpg",
      "pollination2.jpg",
      "pollinationresultscotton.jpg",
      "pollinationresultsfruits.jpg",
      "pollinationresultssunflower.jpg",
    ],
  },
  {
    name: "royaljelly",
    mainImage: "royaljelly.jpg",
    images: ["royaljelly.jpg"],
  },
  {
    name: "tools",
    mainImage: "tools8.jpg",
    images: [
      "tools1.jpg",
      "tools2.jpg",
      "tools4.jpg",
      "tools5.jpg",
      "tools6.jpg",
      "tools8.jpg",
    ],
  },
  {
    name: "waxfoundation",
    mainImage: "waxfoundation3.JPG",
    images: [
      "waxfoundation3.JPG",
      "waxfoundation.jpg",
      "waxfoundation2.JPG",
      "wax.jpg",
    ],
  },
  {
    name: "technology",
    mainImage: "scales.jpg",
    images: ["scales.jpg"],
  },
];

export const partners = [
  {
    name: "arizor",
    logo: "logo1.svg",
    url: "https://www.arizor.uz",
  },
  {
    name: "beekeepers",
    logo: "union_ru.png",
    url: "https://www.beekeepers.uz",
  },
  {
    name: "encyclopdia",
    logo: "enc.jpg",
    url: "https://www.beekeepers.uz",
  },
  {
    name: "fsp",
    logo: "bazafront.jpg",
    url: "https://www/fsp.uz",
  },
];

export const images = [
  "apiary_grapes.jpg",
  "apislavia.jpg",
  "beehives.JPG",
  "beehives2.jpg",
  "beehives3.jpg",
  "beehives4.jpg",
  "beehives5.jpg",
  "beehives6.jpg",
  "candymachines.JPG",
  "classroom.jpg",
  "classroom2.jpg",
  "colony.jpg",
  "colony1.jpg",
  "colony2.jpg",
  "colony3.jpg",
  "colony4.jpg",
  "colony5.jpg",
  "courses.JPG",
  "courses2.JPG",
  "courses23.jpg",
  "facelia.JPG",
  "facelia2.jpg",
  "facelia3.jpg",
  "gaydar.jpg",
  "gaydar1.jpg",
  "gaydar2.jpg",
  "hives2.jpg",
  "honey.JPG",
  "honey2.jpg",
  "honey3.jpg",
  "honey4.jpg",
  "honeyexport.jpg",
  "mites.jpg",
  "mites1.jpg",
  "mites3.jpg",
  "nucs.jpg",
  "office.jpg",
  "officeinside.jpg",
  "officeinside1.jpg",
  "packages.JPG",
  "pollination.jpg",
  "pollination2.jpg",
  "productionhives.jpg",
  "purequeens.jpg",
  "queens.JPG",
  "queens2.jpg",
  "queens3.jpg",
  "raps.jpg",
  "royaljelly.jpg",
  "scales.jpg",
  "seeds.jpg",
  "shakhimardan.jpg",
  "tools1.jpg",
  "tools2.jpg",
  "tools4.jpg",
  "tools5.jpg",
  "tools6.jpg",
  "tools8.jpg",
  "ukr.jpg",
  "ukr1.jpg",
  "union.jpg",
  "union1.jpg",
  "union3.jpg",
  "union4.jpg",
  "union5.jpg",
  "union6.jpg",
  "wax.jpg",
  "waxfoundation.jpg",
  "waxfoundation2.JPG",
  "waxfoundation3.JPG",
  "worker.jpg",
  "youngbeekeeper.jpg",
  // new images 06.03.2024
  "apiary4.JPG",
  "apiaryavval1.jpg",
  "apiaryavval2.jpg",
  "apiaryavval3.jpg",
  "broodframe1.jpg",
  "broodframe2.jpg",
  "broodframe3.jpg",
  "broodframe4.jpg",
  "drones.jpg",
  "faceliabee.JPG",
  "faceliabee2.JPG",
  "frame.JPG",
  "frame2.jpg",
  "frame3.jpg",
  "gsmscalesnew1.JPG",
  "gsmscalesnew2.JPG",
  "honeyfairtashkent.jpg",
  "honeyframe1.jpg",
  "honeyframe2.jpg",
  "honeyframe3.jpg",
  "honeyframe4.jpg",
  "honeyframe5.jpg",
  "honeyframe6.jpg",
  "honeyframe7.jpg",
  "incubator1.jpg",
  "incubator2.jpg",
  "matedqueens.jpg",
  "matedqueen5.jpg",
  "matedqueens2.jpg",
  "matedqueens3.jpg",
  "matedqueens4.jpg",
  "matedqueens6.jpg",
  "micnucs2.jpg",
  "micronucframe.jpg",
  "micronucframe2.jpg",
  "purequeens.jpg",
  "purequeens2.jpg",
  "purequeens3.jpg",
  "purequeens4.jpg",
  "purequeenspassport1.jpg",
  "purequeenspassport2.jpg",
  "queen.jpg",
  "queencell.jpg",
  "queencellsnew1.jpg",
  "queencellsnew2.jpg",
  "queencellsnew3.jpg",
  "queencellsnew4.jpg",
  "queencellsnew5.jpg",
  "queencellsnew6.jpg",
  "queencellsnew7.jpg",
  "queencellsnew8.jpg",
  "queenwithmark.jpg",
  "queenwithmark2.JPG",
  "samandar.jpg",
  "samandar2.jpg",
  "seminarnew1.jpg",
  "seminarnew2.jpg",
  "shakhimardanapiary.jpg",
  "shakhimardanapiary2.jpg",
  "shakhimardanapiary3.jpg",
  "shakhimardanapiary4.jpg",
  "shakhimardanapiary5.jpg",
  "shakhimardanapiary6.jpg",
  "shakhimardanapiary7.jpg",
  "shakhimardanapiary8.jpg",
  "shakhimardanapiary9.jpg",
  "shakhimardanapiary10.jpg",
  "shakhimardanapiary11.jpg",
  "shakhimardanapiary12.jpg",
  "windowcolony.JPG",
  "yardanapiary.jpg",
  "pollinationresultscotton.jpg",
  "pollinationresultsfruits.jpg",
  "pollinationresultssunflower.jpg",
  "propolis.jpg",
  "propolis2.jpg",
];

import React from "react";
import { useForm } from "react-hook-form";
import CustomInput from "./CustomInput";
import CustomTextArea from "./CustomTextArea";

const ContactUsModal = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  const fields = [
    {
      name: "username",
      placeholder: "Enter your name",
      label: "Name",
      icon: (
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="orange"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
      ),
      validation: {
        required: "This field is required",
        maxLength: { value: 30, message: "Not more than 30 characters" },
      },
    },
    {
      name: "email",
      placeholder: "Enter your email address",
      label: "Email",
      icon: (
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="orange"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
          />
        </svg>
      ),
      validation: {
        required: "This field is required",
        maxLength: { value: 50, message: "Not more than 50 characters" },
        pattern: {
          value: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
          message: "Enter valid email address",
        },
      },
    },
    {
      name: "subject",
      placeholder: "Enter the subject",
      label: "Subject",
      validation: {
        required: "This field is required",
        maxLength: { value: 50, message: "Not more than 50 characters" },
      },
    },
  ];

  return (
    <div className="bg-white p-4 max-w-2xl rounded-xl m-auto">
        <div>Contact Us</div>
      <form
        className="mt-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        {fields.map((field) => {
          return (
            <CustomInput
              key={field.name}
              validation={field.validation}
              register={register}
              name={field.name}
              placeholder={field.placeholder}
              label={field.label}
              errors={errors[field.name] && errors[field.name].message}
            >
              {field.icon}
            </CustomInput>
          );
        })}

        <CustomTextArea
          name="message"
          validation={{
            required: "This field is required",
            maxLength: { value: 500, message: "Not more than 50 characters" },
          }}
          register={register}
          rows="8"
          placeholder="Enter your message"
          maxChars="500"
          errors={errors.message && errors.message.message}
        />

        <button
          className="bg-white 
                                hover:bg-gray-100 
                                text-gray-800 
                                font-semibold
                                py-2 px-4 border 
                                border-gray-400 
                                rounded shadow"
          type="submit"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactUsModal;

import React, { useEffect, useState } from "react";
import { images } from "../xmls/products";
import { useTranslation } from "react-i18next";
import Modal from "../general/Modal";

const Gallery = () => {
  const { t, i18n } = useTranslation(["translation", "products", "images"]);
  let content = [];
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", (event) => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {}, []);

  const nipr = Math.round(window.innerWidth / 400);
  const rows = images.length / (window.innerWidth / 400);

  const viewImage = (img) => {
    const div = (
      <div className="max-h-screen">
        <img
          src={require(`../../assets/images/gallery/${img}`)}
          className={`mx-auto object-scale-down xl:w-2/4 lg:w-3/4 sm:w-3/4 w-full h-screen`}
        />

        <div className="absolute p-4 top-0 bg-black bg-opacity-70 text-white">
          {t(`images:${img}`)}
        </div>
      </div>
    );

    setCurrentImage(div);
    setShowModal(true);
  };

  for (let i = 0; i < rows; i++) {
    let row = [];
    images.slice(nipr * i, nipr * (i + 1)).map((image, index) => {
      row.push(
        <div
          key={image}
          className="relative group cursor-pointer p-0.5 content-center text-center"
        >
          <img
            src={require(`../../assets/images/thumbs/${image}`)}
            className="h-full w-full m-auto object-cover"
            onClick={() => viewImage(image)}
          />
          <div className="absolute p-4 top-0 bg-black bg-opacity-70 text-white opacity-0 group-hover:opacity-100">
            {t(`images:${image}`)}
          </div>
        </div>
      );
    });
    content.push(
      <div key={i} className="flex flex-row justify-center">
        {row}
      </div>
    );
  }

  return (
    <div className="flex flex-col  pt-7">
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {currentImage}
      </Modal>
      {content}
    </div>
  );
};

export default Gallery;

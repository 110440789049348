import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavbarLink = ({ name, to }) => {
  const { t, i18n } = useTranslation(["translation", "products"]);
  const location = useLocation();
  return (
    <>
      <Link
        to={to}
        className={`py-4 px-6 border-b-2 text-yellow-600 
            border-yellow-500 border-opacity-0 hover:border-opacity-100 
            hover:text-yellow-500 duration-200 cursor-pointer font-roboto ${
              location.pathname == to && "border-opacity-100"
            }`}
      >
        {t(name)}
      </Link>
    </>
  );
};

export default NavbarLink;

import React from "react";
import Navbar from "../Navbar/Navbar";
import ContactUsFloatingBtn from "../general/ContactUsFloatingBtn";
import Footer from "../footer/Footer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <Navbar />
      <ContactUsFloatingBtn />
      <div className="">
        <Outlet />
      </div>
      {<Footer />}
    </>
  );
};

export default Layout;

const menuItems = [
  {
    name: "home",
    url: "/",
    icon: null,
  },
  {
    name: "aboutustitle",
    url: "/about",
    icon: null,
  },
  { name: "contactstitle", url: "/contacts", icon: null },
  {
    name: "poductstitle",
    url: "/products",
    icon: null,
  },
  {
    name: "partners",
    url: "/partners",
    icon: null,
  },

  // {
  //   name: "news",
  //   url: "/news",
  //   icon: null,
  // },
  {
    name: "gallery",
    url: "/gallery",
    icon: null,
  },
];

const ourPartners = [
  {
    name: "fsp",
    url: "https://www.fsp.uz",
    icon: null,
  },
  {
    name: "beekeepers",
    url: "https://www.beekeepers.uz",
    icon: null,
  },
  {
    name: "arizor",
    url: "https://www.arizor.uz",
    icon: null,
  },
  {
    name: "encyclopdia",
    url: "https://play.google.com/store/apps/details?id=com.arizoruz.beekeeping_encyclopedia",
    icon: null,
  },
];

const allLinks = {
  home: menuItems,
  partners: ourPartners,
};

export { menuItems, ourPartners, allLinks };

import React from 'react'
import { useForm } from 'react-hook-form'


const CustomInput = (props) => {
    const { register, formState: { errors } } = useForm();
    return (
        <div className="flex flex-col mb-5">
            <label
                className="mb-1 text-xs tracking-wide text-gray-600 flex"
            >{props.label}:</label>
            <div className="relative w-full">
                <div
                    className="
                    inline-flex
                    items-center
                    justify-center
                    absolute
                    left-0
                    top-0
                    h-full
                    w-10
                    text-gray-400
                  "
                >
                    {props.children}
                </div>

                <input {...props.register(props.name, props.validation)}
                    className={`text-lg 
                    placeholder-gray-500 ${props.children == null ? "pl-2" : "pl-10" } 
                    pr-4 rounded-2xl border  
                    w-full py-2 focus:outline-none 
                    ${props.errors!= null ? "focus:border-red-500 border-red-500" : 
                    "focus:border-blue-400 border-gray-400"}`}
                    placeholder={props.placeholder}
                    type={props.type != null ? props.type : "text"}
                />

                
            </div>
            {props.errors && <p className="text-red-500 text-sm font-sans flex">{props.errors}</p>}
        </div>
    )
}

export default CustomInput

import React from "react";

const GoBackBtn = (props) => {
  return (
    <button
      onClick={props.callBackFn}
      className="hover:bg-yellow-200
                bg-gray-50
                bg-opacity-40
                rounded-full
                flex
                align-middle
                pr-4 group"
    >
      <svg
        className="w-12 text-orange group-hover:text-black"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <span className="my-auto font-roboto font-normal text-black">
        {props.children}
      </span>
    </button>
  );
};

export default GoBackBtn;

import React, { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { menuItems } from "../xmls/Links";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModalMenu = ({ showModal, setShowModal, children }) => {
  const { t, i18n } = useTranslation(["translation"]);
  const location = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 140px 140px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(0px at 40px 40px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      variants={sidebar}
      initial={false}
      animate={showModal ? "open" : "closed"}
      className="
          fixed 
        flex bg-black bg-opacity-80 top-0 left-0 w-full h-full z-50 justify-end"
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div className="relative bg-white w-80">
        <button
          onClick={() => {
            setShowModal(false);
          }}
          className="absolute left-2 top-2 hover:bg-yellow-200
                bg-gray-50
                bg-opacity-30
                rounded-full
                flex
                align-middle
                p-2
                group"
        >
          <svg
            className="w-12 text-orange group-hover:text-black"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            />
          </svg>
        </button>

        <motion.ul
          variants={container}
          initial="hidden"
          animate="visible"
          className="float-right pt-16 w-full"
        >
          {menuItems.map((item, index) => {
            return (
              <li
                key={index}
                className={`${
                  location.pathname == item.url && "bg-yellow-200"
                } flex group justify-between
                   hover:bg-yellow-200 
                   py-4 px-8
                   text-yellow-600 
                   font-semibold font-opensans cursor-pointer hover:text-black`}
                onClick={() => {
                  navigate(item.url, { replace: true });
                }}
              >
                <div className="text-transparent group-hover:text-black">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </div>
                <div className="text-right">{t(item.name)}</div>
              </li>
            );
          })}
        </motion.ul>
      </div>
    </motion.div>
  );
};

export default ModalMenu;

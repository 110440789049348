import React from "react";
import { motion } from "framer-motion";
import { partners } from "../xmls/products";
import PartnersCard from "../general/PartnersCard";
import { useTranslation } from "react-i18next";

const Partners = (props) => {
  const { t } = useTranslation(["translation", "partners"]);

  const itemFromTop = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="bg-shakhimardan min-h-screen bg-cover bg-center -mt-20 pt-7">
      <div className="container mx-auto pt-20 px-4 w-full">
        <div className="font-sans font-light text-xl text-justify w-full m-auto space-y-4 mt-10">
          <div
            className={`text-lg 
        bg-white 
        bg-opacity-70 flex
    flex-col
        bg-contain 
        text-justify 
        border border-yellow-400 
        rounded-3xl 
        shadow-2xl 
        px-5 py-5 w-full`}
          >
            <motion.div
              className="text-center text-4xl font-lobster font-bold uppercase pb-3 border-b-4 border-yellow-600"
              variants={itemFromTop}
            >
              {t("partners")}
            </motion.div>
            <div className="grid grid-cols-1 gap-6 pt-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
              {partners.map((item, index) => {
                return <PartnersCard key={index} item={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { products } from "../xmls/products";
import { useTranslation } from "react-i18next";
import Hexagon from "../general/Hexagon";
import { motion } from "framer-motion";
import GoBackBtn from "../general/GoBackBtn";
import Modal from "../general/Modal";

const icon = {
  hidden: {
    opacity: 0.5,
    pathLength: 0,
    strokeWidth: 5,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    strokeWidth: 5,
  },
};

const Product = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["translation", "products", "images"]);
  let items = [];

  let { productId } = useParams();
  let product = products.find((obj) => obj.name === productId);

  const [[image, index], setImage] = useState([product.images[0], 0]);

  Object.keys(t(`products:${product.name}`, { returnObjects: true })).map(
    (key) => {
      if (key !== "title") {
        items.push(`products:${product.name}.${key}`);
      }
    }
  );

  const viewImage = (img) => {
    const div = (
      <div className="max-h-screen">
        <img
          src={require(`../../assets/images/gallery/${img}`)}
          className={`mx-auto object-scale-down xl:w-2/4 lg:w-3/4 sm:w-3/4 w-full h-screen`}
        />

        <div className="absolute p-4 top-0 bg-black bg-opacity-70 text-white">
          {t(`images:${img}`)}
        </div>
      </div>
    );

    setCurrentImage(div);
    setShowModal(true);
  };

  const nextImage = () => {
    if (index >= product.images.length - 1) {
      setImage([product.images[0], 0]);
    } else {
      setImage([product.images[index + 1], index + 1]);
    }
  };

  const prevImage = () => {
    if (index == 0) {
      setImage([
        product.images[product.images.length - 1],
        product.images.length - 1,
      ]);
    } else {
      setImage([product.images[index - 1], index - 1]);
    }
  };

  return (
    <div className="bg-baza bg-fixed min-h-screen bg-cover bg-center -mt-20  pt-7">
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {currentImage}
      </Modal>
      <div className="container mx-auto pt-24 pb-10 px-4">
        <section id="general">
          {" "}
          <GoBackBtn
            className="float-left top-0 absolute"
            callBackFn={() => {
              navigate(`/products/`, { replace: true });
            }}
          >
            {t("translation:products")}
          </GoBackBtn>
          <div className="flex flex-col xl:flex-row pt-4 space-x-10">
            <div className="flex-1 inline-block p-4 my-auto">
              <Hexagon
                className="mx-auto"
                title={`products:${product.name}.title`}
                items={items}
                size="full"
              />
            </div>
            <div className="flex flex-1">
              <div className="flex-none align-middle my-auto p-1">
                {product.images.length > 1 && (
                  <button
                    onClick={prevImage}
                    className="hover:bg-yellow-200 rounded-full text-center bg-white bg-opacity-40"
                  >
                    <svg
                      className="w-12 text-orange hover:text-black"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div className="flex-grow">
                <motion.svg
                  viewBox="0 0 694 800"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                  onClick={() => viewImage(image)}
                >
                  <defs>
                    <pattern
                      id={`image_0`}
                      patternUnits="userSpaceOnUse"
                      width="100%"
                      height="100%"
                    >
                      <image
                        key={index}
                        href={require(`../../assets/images/thumbs/${image}`)}
                        x="0"
                        y="-25%"
                        width="150%"
                        height="150%"
                      />
                    </pattern>
                  </defs>
                  <motion.path
                    variants={icon}
                    initial="hidden"
                    animate="visible"
                    transition={{
                      default: { duration: 3, ease: "easeInOut" },
                    }}
                    d="M1.58984 200.577L347 1.1547L692.41 200.577V599.423L347 798.845L1.58984 599.423V200.577Z"
                    className="shadow"
                    fill={`url(#image_0)`}
                    stroke="#FBB500"
                    strokeWidth="5"
                  />
                </motion.svg>
              </div>
              <div className="flex-none align-middle my-auto p-1">
                {product.images.length > 1 && (
                  <button
                    onClick={nextImage}
                    className="hover:bg-yellow-200 rounded-full bg-white bg-opacity-40"
                  >
                    <svg
                      className="w-12 text-orange hover:text-black"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Product;

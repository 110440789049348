import React from "react";
import NavbarLink from "./NavbarLink";
import { useState } from "react";
import { menuItems } from "../xmls/Links";
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import ModalMenu from "../general/ModalMenu";

const NavbarMenu = ({ navbarStatus }) => {
  const [navbar, setNavbar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(["translate", "products"]);

  const changeNavbarBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
    navbarStatus(navbar);
  };

  const showModalMenu = () => {
    setShowModal(true);
  };

  window.addEventListener("scroll", changeNavbarBackground);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const link = {
    hidden: { y: -20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const icon = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
    },
  };

  return (
    <>
      <ModalMenu showModal={showModal} setShowModal={setShowModal} />

      <header
        variants={container}
        className={`bg-white ${
          navbar ? "bg-opacity-100 shadow-lg" : "bg-opacity-0"
        } flex text-right p-4 px-10 py-02 z-10 relative justify-end`}
      >
        <div className="absolute -left-10 md:left-0">
          <motion.svg
            width="305"
            height="80"
            viewBox="0 0 240 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              transition={{
                default: { duration: 2, ease: "easeInOut" },
              }}
              variants={icon}
              key="logo"
              initial="hidden"
              animate="visible"
              d="M2.53848 31.4434L52 2.88675L101.462 31.4434V88.5566L52 117.113L2.53848 88.5566V31.4434Z"
              fill={navbar ? "white" : "none"}
              stroke="#FBB500"
              strokeWidth="5"
            />
            <text
              x="8%"
              y="75%"
              fill="black"
              fontFamily="Lobster"
              fontSize={80}
            >
              Arichi-1
            </text>
          </motion.svg>
        </div>

        <nav
          className={`cursor-pointer nav font-semibold text-lg xl:opacity-0  my-auto shadow ${
            navbar ? "text-black" : "text-white"
          }`}
          onClick={setShowModal}
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </nav>

        <nav className="nav font-semibold text-lg xl:block hidden">
          <motion.ul
            variants={container}
            initial="hidden"
            animate="visible"
            className="flex items-center"
          >
            {menuItems.map((item) => {
              return (
                <motion.li variants={link} key={item.name}>
                  <NavbarLink to={item.url} name={item.name} />
                </motion.li>
              );
            })}
          </motion.ul>
        </nav>
      </header>
    </>
  );
};

export { NavbarMenu };

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

axios.defaults.baseURL = "http://localhost:5000";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: { username: "", createdAt: "", id: null },
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;

const { loginSuccess, logoutSuccess, setUser } = userSlice.actions;

export const login = (data, setMsg) => async (dispatch) => {
  try {
    return await axios
      .post("/user/login", data)
      .then((res) => {
        localStorage.setItem("token", res.data.accessToken);
        return dispatch(loginSuccess(res.data.user));
      })
      .catch((err) => {
        setMsg(err.response.data.message);
        return false;
      });
  } catch (e) {
    return false;
  }
};

export const logout = () => async (dispatch) => {
  try {
    return dispatch(logoutSuccess);
  } catch (err) {
    return console.error(err.message);
  }
};

export const user = (user) => async (dispatch) => {
  try {
    return dispatch(setUser(user));
  } catch (err) {
    return console.error(err.message);
  }
};

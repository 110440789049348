import React from "react";
import { AnimatePresence } from "framer-motion";

const Modal = ({ showModal, setShowModal, children }) => {
  return (
    <AnimatePresence>
      {showModal && (
        <div className="fixed bg-black bg-opacity-80 top-0 left-0 w-full h-full z-50 text-center align-middle ">
          <div className="p-8 relative h-screen">
            {children}
            <div className="absolute right-2 top-2">
              <svg
                className="w-10 h-10 text-white cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowModal(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Modal;

import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Hexagon = (props) => {
  const { t, i18n } = useTranslation(["translation", "products"]);

  const container = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delayChildren: 0.3,
        staggerChildren: 0.5,
      },
    },
  };

  const itemFromRight = {
    hidden: { x: 100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  const itemFromLeft = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const itemFromTop = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <motion.div
      className={`text-lg
        bg-white 
        bg-opacity-80 
        max-w-${props.size == null ? "xl" : props.size} 
        bg-contain 
        text-justify 
        border border-yellow-400 
        rounded-3xl 
        shadow-2xl 
        px-5 py-5`}
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="text-center text-4xl font-lobster font-bold uppercase pb-3 border-b-4 border-yellow-600"
        variants={itemFromTop}
      >
        {t(props.title)}
      </motion.div>

      <motion.div
        className="
                        px-5
                        py-5
                        space-y-5
                        font-sans"
      >
        {props.items.map((text, index) => {
          return (
            <motion.p
              key={index}
              variants={index % 2 == 0 ? itemFromRight : itemFromLeft}
            >
              {t(text)}
            </motion.p>
          );
        })}
      </motion.div>
    </motion.div>
  );
};

export default Hexagon;

import React from "react";
import image from "../../assets/images/gallery/faceliabee.JPG";
import Hexagon from "../general/Hexagon";
import { motion } from "framer-motion";
const Contacts = () => {
  const items = [
    "translation:contacts.address",
    "translation:contacts.telephone",
    "translation:contacts.email",
  ];

  const icon = {
    hidden: {
      opacity: 0.5,
      pathLength: 0,
      strokeWidth: 5,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      strokeWidth: 5,
    },
  };

  return (
    <div className="bg-apiary min-h-screen bg-cover bg-center -mt-20 pt-7">
      <div className="container mx-auto pt-20 px-4">
        <div className="flex flex-col xl:flex-row pt-4">
          <div className="font-sans font-light text-xl text-justify flex-1 m-auto space-y-4">
            <Hexagon
              className="mx-auto"
              title={"translation:contactstitle"}
              items={items}
              size="full"
            />
          </div>
          <div className="flex-1 m-auto md:px-24 pt-10 xl:pt-0 content-center">
            <motion.svg
              width="100%"
              height="100%"
              viewBox="0 0 694 800"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern
                  id="img1"
                  patternUnits="userSpaceOnUse"
                  width="100%"
                  height="100%"
                >
                  <image
                    href={image}
                    x="-25%"
                    y="-12%"
                    width="180%"
                    height="120%"
                  />
                </pattern>
              </defs>
              <motion.path
                variants={icon}
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 5, ease: "easeInOut" },
                }}
                d="M1.58984 200.577L347 1.1547L692.41 200.577V599.423L347 798.845L1.58984 599.423V200.577Z"
                className="shadow"
                fill="url(#img1)"
                stroke="#FBB500"
                strokeWidth="5"
              />
            </motion.svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import React, { useState } from "react";
import ContactUsModal from "./ContactUsModal";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const ContactUsFloatingBtn = () => {
  const { t, i18n } = useTranslation(["translation", "products"]);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  return (
    <>
      <Modal key={"modal1"} showModal={modal} setShowModal={setModal}>
        <ContactUsModal />
      </Modal>

      <Modal key={"modal2"} showModal={modal2} setShowModal={setModal2}>
        <div
          className="text-xl text-blue-900 fixed bottom-36 right-6 
        rounded-full border-2 border-yellow-300 p-3 shadow-lg cursor-pointer bg-white z-10 uppercase"
          onClick={() => {
            i18n.changeLanguage("ru");
            setModal2(!modal2);
          }}
        >
          RU
        </div>
        <div
          className="text-xl text-blue-900 fixed bottom-20 right-24 
        rounded-full border-2 border-yellow-300 p-3 shadow-lg cursor-pointer bg-white z-10 uppercase"
          onClick={() => {
            i18n.changeLanguage("uz");
            setModal2(!modal2);
          }}
        >
          UZ
        </div>
        <div
          className="text-xl text-blue-900 fixed bottom-6 right-6 
        rounded-full border-2 border-yellow-300 p-3 shadow-lg cursor-pointer bg-white z-10 uppercase"
          onClick={() => {
            i18n.changeLanguage("en");
            setModal2(!modal2);
          }}
        >
          EN
        </div>
      </Modal>
      <div
        className="text-2xl text-blue-900 fixed bottom-6 right-6 
        rounded-full border-2 border-yellow-300 p-3 shadow-lg cursor-pointer bg-white z-10"
        onClick={() => setModal(!modal)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="blue"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
          />
        </svg>
      </div>
      <div
        className="text-xl text-blue-900 fixed bottom-20 right-6 
        rounded-full border-2 border-yellow-300 p-3 shadow-lg cursor-pointer bg-white z-10 uppercase"
        onClick={() => setModal2(!modal)}
      >
        {i18n.language}
      </div>
    </>
  );
};

export default ContactUsFloatingBtn;
